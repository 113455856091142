<template>
    <base-dialog width="722" :title="dynamicTitles.card" :is-open.sync="dialog">
        <v-alert tile type="error" v-if="!!error">
            {{ error }}
        </v-alert>

        <v-card-text class="pt-7 px-2">
            <v-container fluid>
                <v-row>
                    <v-col cols="12" class="mb-5">
                        <v-row>
                            <v-col cols="12">
                                <span
                                    class="text-md font-weight-bold section-header"
                                    >Personal</span
                                >
                            </v-col>

                            <v-col cols="12">
                                <v-row>
                                    <v-col md="6" cols="12">
                                        <base-input-label required
                                            >First Name</base-input-label
                                        >
                                        <v-text-field
                                            solo
                                            rounded
                                            class="rounded-lg"
                                            flat
                                            hide-details
                                            v-model="form.first_name"
                                        ></v-text-field
                                    ></v-col>

                                    <v-col md="6" cols="12">
                                        <base-input-label optional
                                            >Middle Name</base-input-label
                                        >
                                        <v-text-field
                                            solo
                                            rounded
                                            class="rounded-lg"
                                            flat
                                            hide-details
                                            v-model="form.middle_name"
                                        ></v-text-field
                                    ></v-col>

                                    <v-col md="6" cols="12">
                                        <base-input-label required
                                            >Last Name</base-input-label
                                        >
                                        <v-text-field
                                            solo
                                            rounded
                                            class="rounded-lg"
                                            flat
                                            hide-details
                                            v-model="form.last_name"
                                        ></v-text-field
                                    ></v-col>

                                    <v-col md="6" cols="12">
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <base-input-label required
                                                    >Prefix</base-input-label
                                                >
                                                <v-select
                                                    solo
                                                    rounded
                                                    class="rounded-lg"
                                                    flat
                                                    hide-details
                                                    :items="prefixes"
                                                    v-model="form.prefix"
                                                ></v-select>
                                            </v-col>

                                            <v-col md="6" cols="12">
                                                <base-input-label optional
                                                    >Suffix</base-input-label
                                                >
                                                <v-text-field
                                                    solo
                                                    rounded
                                                    class="rounded-lg"
                                                    flat
                                                    hide-details
                                                    v-model="form.suffix"
                                                ></v-text-field
                                            ></v-col>
                                        </v-row>
                                    </v-col>

                                    <v-col md="6" cols="12">
                                        <base-input-label required
                                            >Nickname</base-input-label
                                        >
                                        <v-text-field
                                            solo
                                            rounded
                                            class="rounded-lg"
                                            flat
                                            hide-details
                                            v-model="form.nickname"
                                        ></v-text-field
                                    ></v-col>

                                    <v-col cols="12" md="6">
                                        <base-input-label required
                                            >Birthdate</base-input-label
                                        >
                                        <base-date-picker
                                            solo
                                            rounded
                                            custom-class="rounded-lg"
                                            flat
                                            hide-details
                                            color="secondary"
                                            v-model="form.birthdate"
                                        ></base-date-picker>
                                    </v-col>

                                    <v-col cols="12" md="6">
                                        <base-input-label required
                                            >Gender</base-input-label
                                        >
                                        <v-select
                                            solo
                                            rounded
                                            class="rounded-lg"
                                            flat
                                            hide-details
                                            :items="genders"
                                            v-model="form.gender"
                                        ></v-select>
                                    </v-col>

                                    <v-col cols="12" md="6">
                                        <base-input-label required
                                            >Marital Status</base-input-label
                                        >
                                        <v-select
                                            solo
                                            rounded
                                            class="rounded-lg"
                                            flat
                                            hide-details
                                            :items="maritalStatuses"
                                            v-model="form.marital_status"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>

                    <!--                    <v-col cols="12" class="mb-5">-->
                    <!--                        <v-row>-->
                    <!--                            <v-col cols="12">-->
                    <!--                                <span-->
                    <!--                                    class="text-md font-weight-bold section-header"-->
                    <!--                                    >Password</span-->
                    <!--                                >-->
                    <!--                            </v-col>-->

                    <!--                            <v-col cols="12">-->
                    <!--                                <v-row>-->
                    <!--                                    <v-col md="6" cols="12">-->
                    <!--                                        <base-input-label required-->
                    <!--                                            >Password</base-input-label-->
                    <!--                                        >-->
                    <!--                                        <v-text-field-->
                    <!--                                            solo-->
                    <!--                                            rounded-->
                    <!--                                            class="rounded-lg"-->
                    <!--                                            flat-->
                    <!--                                            hide-details-->
                    <!--                                            type="password"-->
                    <!--                                            v-model="form.password"-->
                    <!--                                        ></v-text-field-->
                    <!--                                    ></v-col>-->

                    <!--                                    <v-col md="6" cols="12">-->
                    <!--                                        <base-input-label required-->
                    <!--                                            >Confirm Password</base-input-label-->
                    <!--                                        >-->
                    <!--                                        <v-text-field-->
                    <!--                                            solo-->
                    <!--                                            rounded-->
                    <!--                                            class="rounded-lg"-->
                    <!--                                            flat-->
                    <!--                                            hide-details-->
                    <!--                                            type="password"-->
                    <!--                                            v-model="form.password_confirmation"-->
                    <!--                                        ></v-text-field-->
                    <!--                                    ></v-col>-->
                    <!--                                </v-row>-->
                    <!--                            </v-col>-->
                    <!--                        </v-row>-->
                    <!--                    </v-col>-->

                    <v-col cols="12" class="mb-5">
                        <v-row>
                            <v-col cols="12">
                                <span
                                    class="text-md font-weight-bold section-header"
                                    >Contact</span
                                >
                            </v-col>

                            <v-col cols="12">
                                <v-row>
                                    <v-col md="6" cols="12">
                                        <base-input-label required
                                            >Contact Number</base-input-label
                                        >
                                        <v-text-field
                                            solo
                                            rounded
                                            class="rounded-lg"
                                            flat
                                            hide-details
                                            v-model="form.contact_number"
                                        ></v-text-field
                                    ></v-col>

                                    <v-col md="6" cols="12">
                                        <base-input-label required
                                            >Email Address</base-input-label
                                        >
                                        <v-text-field
                                            solo
                                            rounded
                                            class="rounded-lg"
                                            flat
                                            hide-details
                                            v-model="form.email"
                                        ></v-text-field
                                    ></v-col>

                                    <v-col cols="12">
                                        <base-input-label required>
                                            Address</base-input-label
                                        >
                                        <v-text-field
                                            solo
                                            rounded
                                            class="rounded-lg"
                                            flat
                                            hide-details
                                            v-model="form.address"
                                        ></v-text-field
                                    ></v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col cols="12" class="mb-5">
                        <v-row>
                            <v-col cols="12">
                                <span
                                    class="text-md font-weight-bold section-header"
                                    >Work</span
                                >
                            </v-col>

                            <v-col cols="12">
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <base-input-label optional
                                            >Occupation</base-input-label
                                        >
                                        <v-text-field
                                            solo
                                            rounded
                                            class="rounded-lg"
                                            flat
                                            hide-details
                                            v-model="form.occupation"
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="12" md="6">
                                        <base-input-label optional>
                                            Company
                                        </base-input-label>
                                        <v-text-field
                                            solo
                                            rounded
                                            class="rounded-lg"
                                            flat
                                            hide-details
                                            v-model="form.company"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col cols="12" class="mb-5">
                        <v-row>
                            <v-col cols="12">
                                <span
                                    class="text-md font-weight-bold section-header"
                                    >Family</span
                                >
                            </v-col>

                            <v-col cols="12">
                                <v-row>
                                    <v-col cols="12">
                                        <base-input-label optional
                                            >Father</base-input-label
                                        >
                                        <v-text-field
                                            solo
                                            rounded
                                            class="rounded-lg"
                                            flat
                                            hide-details
                                            :value="form.father.name"
                                            readonly
                                            @click="
                                                openSearchFamilyDialog('father')
                                            "
                                            clearable
                                            @click:clear="
                                                clearCustomSearchInput('father')
                                            "
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="12">
                                        <base-input-label optional
                                            >Mother</base-input-label
                                        >
                                        <v-text-field
                                            solo
                                            rounded
                                            class="rounded-lg"
                                            flat
                                            hide-details
                                            :value="form.mother.name"
                                            readonly
                                            @click="
                                                openSearchFamilyDialog('mother')
                                            "
                                            clearable
                                            @click:clear="
                                                clearCustomSearchInput('mother')
                                            "
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="12">
                                        <base-input-label optional
                                            >Spouse</base-input-label
                                        >
                                        <v-text-field
                                            solo
                                            rounded
                                            class="rounded-lg"
                                            flat
                                            hide-details
                                            :value="form.spouse.name"
                                            readonly
                                            @click="
                                                openSearchFamilyDialog('spouse')
                                            "
                                            clearable
                                            @click:clear="
                                                clearCustomSearchInput('spouse')
                                            "
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="12">
                                        <base-input-label optional
                                            >Children</base-input-label
                                        >
                                        <v-combobox
                                            solo
                                            rounded
                                            class="rounded-lg"
                                            flat
                                            hide-details
                                            small-chips
                                            readonly
                                            multiple
                                            append-icon=""
                                            @click.native="
                                                openSearchFamilyDialog('child')
                                            "
                                            clearable
                                            @click:clear="
                                                clearCustomSearchInput('child')
                                            "
                                            v-model="form.child"
                                        >
                                            <template
                                                v-slot:selection="{ item }"
                                            >
                                                <v-chip
                                                    class="white--text"
                                                    color="secondary"
                                                    small
                                                    close
                                                    :key="JSON.stringify(item)"
                                                    @click:close="
                                                        removeFamilyMember(
                                                            'child',
                                                            item.id
                                                        )
                                                    "
                                                    >{{ item.name }}</v-chip
                                                >
                                            </template>
                                        </v-combobox>
                                    </v-col>

                                    <v-col cols="12">
                                        <base-input-label optional
                                            >Siblings</base-input-label
                                        >
                                        <v-combobox
                                            solo
                                            rounded
                                            class="rounded-lg"
                                            flat
                                            hide-details
                                            small-chips
                                            readonly
                                            multiple
                                            append-icon=""
                                            @click.native="
                                                openSearchFamilyDialog(
                                                    'sibling'
                                                )
                                            "
                                            clearable
                                            @click:clear="
                                                clearCustomSearchInput(
                                                    'sibling'
                                                )
                                            "
                                            v-model="form.sibling"
                                        >
                                            <template
                                                v-slot:selection="{ item }"
                                            >
                                                <v-chip
                                                    class="white--text"
                                                    color="secondary"
                                                    small
                                                    close
                                                    :key="JSON.stringify(item)"
                                                    @click:close="
                                                        removeFamilyMember(
                                                            'sibling',
                                                            item.id
                                                        )
                                                    "
                                                    >{{ item.name }}</v-chip
                                                >
                                            </template>
                                        </v-combobox>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12">
                                <span
                                    class="text-md font-weight-bold section-header"
                                    >Membership, Designation, and User Type
                                </span>
                            </v-col>

                            <v-col cols="12" md="6">
                                <base-input-label required
                                    >Status</base-input-label
                                >
                                <v-select
                                    solo
                                    rounded
                                    class="rounded-lg"
                                    flat
                                    hide-details
                                    item-value="id"
                                    item-text="name"
                                    :items="memberships"
                                    v-model="form.membership_id"
                                ></v-select>
                            </v-col>

                            <v-col cols="12" md="6">
                                <base-input-label required
                                    >BOE Resolution</base-input-label
                                >
                                <v-text-field
                                    solo
                                    rounded
                                    class="rounded-lg"
                                    flat
                                    hide-details
                                    v-model="form.boe_resolution"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" md="6">
                                <base-input-label required
                                    >Designation</base-input-label
                                >
                                <v-select
                                    solo
                                    rounded
                                    class="rounded-lg"
                                    flat
                                    multiple
                                    small-chips
                                    hide-details
                                    return-object
                                    item-text="name"
                                    :items="designations"
                                    v-model="form.designation_ids"
                                >
                                    <template v-slot:selection="{ item }">
                                        <v-chip
                                            class="white--text"
                                            color="secondary"
                                            small
                                            close
                                            :key="JSON.stringify(item)"
                                            >{{ item.name }}</v-chip
                                        >
                                    </template>
                                </v-select>
                            </v-col>

                            <v-col cols="12" md="6">
                                <base-input-label required
                                    >User Type</base-input-label
                                >
                                <v-select
                                    solo
                                    rounded
                                    class="rounded-lg"
                                    flat
                                    hide-details
                                    multiple
                                    small-chips
                                    return-object
                                    item-text="name"
                                    :items="roles"
                                    v-model="form.role_ids"
                                >
                                    <template v-slot:selection="{ item }">
                                        <v-chip
                                            class="white--text"
                                            color="secondary"
                                            small
                                            close
                                            :key="JSON.stringify(item)"
                                            >{{ item.name }}</v-chip
                                        >
                                    </template>
                                </v-select>
                            </v-col>

                            <v-col cols="12">
                                <base-input-label optional
                                    >Join Date</base-input-label
                                >
                                <base-date-picker
                                    solo
                                    rounded
                                    custom-class="rounded-lg"
                                    flat
                                    hide-details
                                    color="secondary"
                                    v-model="form.join_date"
                                ></base-date-picker>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions class="pa-0">
            <v-container fluid>
                <v-row align="center" justify="center" class="py-1 px-2">
                    <v-col md="2" cols="12" class="px-2">
                        <v-btn
                            block
                            depressed
                            dark
                            color="#389877"
                            rounded
                            :loading="isActionStart"
                            @click="onSave"
                            >Save</v-btn
                        >
                    </v-col>
                </v-row>
            </v-container>
        </v-card-actions>

        <custom-search-dialog
            :is-open.sync="familyDialog.open"
            :title="familyDialog.title"
            :action="getUsersAction"
            @onSelect="onSelectFamily"
        >
            <template v-slot:text="{ item }">
                {{ item.prefix }} {{ item.first_name }} {{ item.last_name }}
            </template>
        </custom-search-dialog>
    </base-dialog>
</template>

<script>
import BaseDialog from "@/components/Base/BaseDialog";
import BaseInputLabel from "@/components/Base/BaseInputLabel";
import BaseDatePicker from "@/components/Base/BaseDatePicker";
import CustomSearchDialog from "@/components/Custom/CustomSearchDialog";
import { CREATE_USER, GET_USERS, UPDATE_USER } from "@/store/modules/user";

const defaultForm = {
    prefix: null,
    first_name: null,
    middle_name: null,
    last_name: null,
    suffix: null,
    nickname: null,
    birthdate: null,
    gender: null,
    marital_status: null,
    password: null,
    password_confirmation: null,
    contact_number: null,
    email: null,
    address: null,
    occupation: null,
    company: null,
    father: {
        id: null,
        name: null,
    },
    mother: {
        id: null,
        name: null,
    },
    spouse: {
        id: null,
        name: null,
    },
    child: [],
    sibling: [],
    membership_id: null,
    boe_resolution: null,
    designation_ids: [],
    role_ids: [],
    group_ids: [],
    join_date: null,
};

export default {
    name: "user-form-dialog",

    components: {
        CustomSearchDialog,
        BaseDatePicker,
        BaseInputLabel,
        BaseDialog,
    },

    props: {
        show: { type: Boolean, default: false, required: true },
        action: { type: String, default: "", required: true },
        data: { type: [Object, null], required: false },
    },

    data() {
        return {
            tab: "",
            dialog: this.show,
            error: null,
            form: Object.assign({}, defaultForm),
            familyDialog: {
                open: false,
                title: null,
                type: null,
            },
            getUsersAction: GET_USERS,
            isActionStart: false,
            userID: null,
        };
    },

    computed: {
        operation() {
            return {
                createMode: this.action === "add",
                editMode: this.action === "edit",
            };
        },

        dynamicTitles() {
            let title = {
                card: null,
            };
            if (this.operation.createMode) {
                title = Object.assign(
                    {},
                    {
                        card: "Add User",
                    }
                );
            } else if (this.operation.editMode) {
                title = Object.assign(
                    {},
                    {
                        card: "Edit User",
                    }
                );
            }

            return title;
        },

        prefixes() {
            return this.$store.state.selection.prefixes;
        },

        genders() {
            return this.$store.state.selection.genders;
        },

        maritalStatuses() {
            return this.$store.state.selection.maritalStatuses;
        },

        memberships() {
            return this.$store.state.profile.memberships;
        },

        milestones() {
            return this.$store.state.profile.milestones;
        },

        designations() {
            return this.$store.state.profile.designations;
        },

        roles() {
            return this.$store.state.profile.roles;
        },

        relationships() {
            return this.$store.state.profile.relationships;
        },
    },

    watch: {
        show(to) {
            this.dialog = to;
        },

        dialog(value) {
            if (!value && this.operation.createMode) {
                this.$emit("close");
            } else if (!value && this.operation.editMode) {
                this.$emit("close");
                this.resetForm();
            }
        },

        data(value) {
            if (
                this.action === "edit" &&
                this.data &&
                Object.keys(value).length > 0
            ) {
                this.toDefaultFormData(value);
            }
        },
    },

    methods: {
        async onSave() {
            if (this.operation.createMode) {
                await this.onCreate();
            } else if (this.operation.editMode) {
                await this.onUpdate();
            }
        },

        async onCreate() {
            let payload = {
                ...this.form,
                relationships: this.toFamilyRelationshipData(),
                designation_ids: this.toDesignationData(),
                role_ids: this.toRoleData(),
            };
            payload = Object.assign({}, this.removePayloadProperties(payload));
            this.isActionStart = true;
            const { success, message } = await this.$store.dispatch(
                CREATE_USER,
                payload
            );
            if (success) {
                this.dialog = false;
                this.$emit("onSuccess");
                this.resetForm();
                this.isActionStart = false;
                this.$notify({
                    group: "main-notifications",
                    type: "success",
                    title: "Ministry",
                    text: "User was created.",
                });
                return;
            }
            this.error = message;
            this.isActionStart = false;
        },

        async onUpdate() {
            let payload = {
                userID: this.userID,
                ...this.form,
                relationships: this.toFamilyRelationshipData(),
                designations: this.toDesignationData(),
                roles: this.toRoleData(),
            };
            payload = Object.assign({}, this.removePayloadProperties(payload));
            this.isActionStart = true;
            const { success, message } = await this.$store.dispatch(
                UPDATE_USER,
                payload
            );
            if (success) {
                this.dialog = false;
                this.$emit("onSuccess");
                this.resetForm();
                this.isActionStart = false;
                this.$notify({
                    group: "main-notifications",
                    type: "success",
                    title: "Ministry",
                    text: "User was updated.",
                });
                return;
            }
            this.error = message;
            this.isActionStart = false;
        },

        openSearchFamilyDialog(type) {
            this.familyDialog = Object.assign(
                {},
                {
                    open: true,
                    title: `Search family member: ${type}`,
                    type,
                }
            );
        },

        onSelectFamily(data) {
            const { type } = this.familyDialog;
            const { id, prefix, first_name, last_name } = data;
            const fullName = `${prefix} ${first_name} ${last_name}`;
            const simpleRelationships = ["father", "mother", "spouse"];
            if (simpleRelationships.includes(type)) {
                this.form[type] = Object.assign(
                    {},
                    {
                        id,
                        name: fullName,
                    }
                );
                return;
            }
            this.form[type] = [
                ...this.form[type],
                {
                    id,
                    name: fullName,
                },
            ];
        },

        removeFamilyMember(type, id) {
            this.form[type] = this.form[type].filter((item) => item.id !== id);
        },

        resetForm() {
            this.error = null;
            this.form = Object.assign({}, defaultForm);
        },

        toFamilyRelationshipData() {
            let rels = []; // rels = relationships
            const simpleRelationships = ["father", "mother", "spouse"];
            const presets = [...simpleRelationships, "child", "sibling"];
            presets.forEach((preset) => {
                const relationship = this.relationships.find(
                    (rel) => rel.name.toLowerCase() === preset
                );
                const notEmpty = this.form[preset].id;
                if (notEmpty && simpleRelationships.includes(preset)) {
                    const newRel = {
                        relate_user_id: this.form[preset].id,
                        relationship_id: relationship.id,
                    };
                    if (this.form[preset].mainID) {
                        newRel.id = this.form[preset].mainID;
                    }
                    rels = [...rels, newRel];
                } else {
                    const notEmpty = this.form[preset].length > 0;
                    if (notEmpty) {
                        this.form[preset].forEach((item) => {
                            const newRel = {
                                relate_user_id: item.id,
                                relationship_id: relationship.id,
                            };
                            if (item.mainID) {
                                newRel.id = item.mainID;
                            }
                            rels = [...rels, newRel];
                        });
                    }
                }
            });

            return rels;
        },

        toDesignationData() {
            let designations = [];
            if (this.operation.createMode) {
                designations = this.form.designation_ids.map(
                    (designation) => designation.id
                );
            } else {
                designations = this.form.designation_ids.map((designation) => {
                    let _designation = { designation_id: designation.id };
                    if (designation.mainID) {
                        _designation = Object.assign(
                            {},
                            {
                                id: designation.mainID,
                                designation_id: designation.id,
                            }
                        );
                    }
                    return _designation;
                });
            }

            return designations;
        },

        toRoleData() {
            let roles = [];
            if (this.operation.createMode) {
                roles = this.form.role_ids.map((role) => role.id);
            } else {
                roles = this.form.role_ids.map((role) => {
                    let _role = { role_id: role.id };
                    if (role.mainID) {
                        _role = Object.assign(
                            {},
                            {
                                id: role.mainID,
                                role_id: role.id,
                            }
                        );
                    }
                    return _role;
                });
            }

            return roles;
        },

        toDefaultFormData(payload) {
            const {
                id,
                user_relationships,
                address,
                birthdate,
                contact_number,
                email,
                first_name,
                gender,
                last_name,
                marital_status,
                middle_name,
                nickname,
                prefix,
                suffix,
                user_roles,
                user_information,
                user_designations,
            } = payload;
            const relationships =
                this.extractFamilyRelationshipDataStructure(user_relationships);
            this.userID = id;
            this.form = Object.assign(
                {},
                {
                    ...this.form,
                    ...relationships,
                    prefix,
                    first_name,
                    middle_name,
                    last_name,
                    suffix,
                    nickname,
                    birthdate,
                    gender,
                    marital_status,
                    contact_number,
                    email,
                    address,
                    occupation: user_information.occupation,
                    company: user_information.company,
                    membership_id: user_information.membership_id,
                    boe_resolution: user_information.boe_resolution,
                    designation_ids: user_designations.map((designation) => ({
                        id: designation.designation.id,
                        name: designation.designation.name,
                        mainID: designation.id,
                    })),
                    role_ids: user_roles.map((role) => ({
                        id: role.role.id,
                        name: role.role.name,
                        mainID: role.id,
                    })),
                    password: null,
                    password_confirmation: null,
                    join_date: user_information.join_date
                        ? user_information.join_date
                        : null,
                }
            );
        },

        extractFamilyRelationshipDataStructure(userRelationships) {
            const simpleRelationships = ["father", "mother", "spouse"];
            const simpleRelationshipsDataContainer = {};
            let complexRelationshipsDataContainer = {}; // Container data of siblings and children
            userRelationships.forEach((userRelationship) => {
                const type = userRelationship.relationship.name.toLowerCase();
                const { id, prefix, first_name, last_name } =
                    userRelationship.relate_user;
                const fullName = `${prefix} ${first_name} ${last_name}`;
                if (simpleRelationships.includes(type)) {
                    simpleRelationshipsDataContainer[type] = Object.assign(
                        {},
                        {
                            id, // this is pointing at user_id
                            mainID: userRelationship.id,
                            name: fullName,
                        }
                    );
                    return;
                }
                if (!complexRelationshipsDataContainer[type]) {
                    complexRelationshipsDataContainer[type] = [];
                }
                complexRelationshipsDataContainer[type] = [
                    ...complexRelationshipsDataContainer[type],
                    {
                        id, // this is pointing at user_id
                        mainID: userRelationship.id,
                        name: fullName,
                    },
                ];
            });

            return {
                ...simpleRelationshipsDataContainer,
                ...complexRelationshipsDataContainer,
            };
        },

        removePayloadProperties(payload) {
            const propClone = Object.assign({}, payload);
            let properties = ["father", "mother", "spouse", "child", "sibling"];
            if (this.operation.editMode) {
                properties = [...properties, "designation_ids", "role_ids"];
            }
            properties.forEach((property) => delete propClone[property]);

            return propClone;
        },

        clearCustomSearchInput(property) {
            const simpleRelationships = ["father", "mother", "spouse"];
            if (simpleRelationships.includes(property)) {
                this.form[property] = Object.assign(
                    {},
                    { id: null, name: null }
                );
                return;
            }
            this.form[property] = [];
        },
    },
};
</script>

<style lang="scss" scoped>
.close-icon {
    transition: 0.3s;
    border-radius: 50px !important;
    margin-top: 20px;
    margin-right: 15px;
    padding: 3px;

    &:hover {
        background-color: #2c423d;
        color: white;
    }
}

.section-header {
    color: #389877;
}
</style>
