<template>
    <base-dialog width="763" :is-open.sync="dialog">
        <v-card-title
            class="font-weight-bold align-center justify-start"
            style="background-color: #f1eeee"
        >
            <span style="color: #78756a"
                >View Milestones of
                <span class="success--text">{{ displayName || "" }}</span></span
            >
        </v-card-title>

        <v-alert tile type="error" v-if="!!error">
            {{ error }}
        </v-alert>

        <v-card-text class="pt-7 px-2">
            <v-container fluid>
                <v-row>
                    <template v-for="(milestone, index) in milestones">
                        <v-col cols="12" :key="index">
                            <!--                            <div class="d-flex align-center">-->
                            <!--                                <v-switch-->
                            <!--                                    inset-->
                            <!--                                    color="accent"-->
                            <!--                                    class="mt-0 mr-8"-->
                            <!--                                    hide-details-->
                            <!--                                    :true-value="1"-->
                            <!--                                    :false-value="0"-->
                            <!--                                    v-model="milestone.status"-->
                            <!--                                >-->
                            <!--                                    <template v-slot:label>-->
                            <!--                                        <span-->
                            <!--                                            class="subtitle-1 font-weight-medium black&#45;&#45;text"-->
                            <!--                                        >-->
                            <!--                                            {{ milestone.milestone.name }}-->
                            <!--                                        </span>-->
                            <!--                                    </template>-->
                            <!--                                </v-switch>-->
                            <!--                                <base-datetime-picker-->
                            <!--                                    :disabled="!milestone.status"-->
                            <!--                                    solo-->
                            <!--                                    hide-details-->
                            <!--                                    flat-->
                            <!--                                    :inline-style="{-->
                            <!--                                        width: '20rem',-->
                            <!--                                        maxWidth: '20rem',-->
                            <!--                                    }"-->
                            <!--                                    v-model="milestone.date_acquired"-->
                            <!--                                ></base-datetime-picker>-->
                            <!--                            </div>-->
                            <base-input-label optional>{{
                                milestone.milestone.name
                            }}</base-input-label>
                            <base-datetime-picker
                                solo
                                hide-details
                                flat
                                v-model="milestone.date_acquired"
                            ></base-datetime-picker>
                        </v-col>
                    </template> </v-row></v-container
        ></v-card-text>

        <v-card-actions class="pa-0">
            <v-container fluid>
                <v-row align="center" justify="center" class="py-1 px-2">
                    <v-col md="2" cols="12" class="px-2">
                        <v-btn
                            block
                            depressed
                            dark
                            color="#389877"
                            rounded
                            :loading="isActionStart"
                            @click="onUpdate"
                            >Save</v-btn
                        >
                    </v-col>
                </v-row>
            </v-container>
        </v-card-actions>
    </base-dialog>
</template>

<script>
import BaseDialog from "@/components/Base/BaseDialog";
import { UPDATE_USER_MILESTONE } from "@/store/modules/user";
import BaseDatetimePicker from "@/components/Base/BaseDateTimePicker";
import dateUtility from "@/mixins/date";
import BaseInputLabel from "@/components/Base/BaseInputLabel";

export default {
    name: "user-milestone-dialog",

    components: { BaseInputLabel, BaseDatetimePicker, BaseDialog },

    props: {
        show: { type: Boolean, default: false, required: true },
        data: { type: [Object, null], required: false },
    },

    data() {
        return {
            dialog: this.show,
            error: null,
            isActionStart: false,
            milestones: [],
        };
    },

    computed: {
        displayName() {
            const { first_name, last_name } = this.data;
            let displayName = "n/a";
            if (first_name && last_name) {
                displayName = `${last_name}, ${first_name}`;
            }
            return displayName;
        },
    },

    watch: {
        show(to) {
            this.dialog = to;
        },

        dialog(value) {
            if (!value) {
                this.$emit("close");
            }
        },
    },

    methods: {
        async onUpdate() {
            const payload = {
                userID: this.data.id,
                milestones: this.toMilestonesPayload(),
            };
            this.isActionStart = true;
            const { success, message } = await this.$store.dispatch(
                UPDATE_USER_MILESTONE,
                payload
            );
            if (success) {
                this.dialog = false;
                this.$emit("onSuccess");
                this.isActionStart = false;
                this.$notify({
                    group: "main-notifications",
                    type: "success",
                    title: "Users",
                    text: "Milestones was updated.",
                });
                return;
            }
            this.error = message;
            this.isActionStart = false;
        },

        toMilestonesPayload() {
            return this.milestones
                .map((milestone) => {
                    const isValidStatus = !!milestone.date_acquired;
                    return {
                        id: milestone.id,
                        status: isValidStatus,
                        date_acquired: isValidStatus
                            ? dateUtility.toUTC(milestone.date_acquired)
                            : null,
                    };
                })
                .filter((item) => item);
        },
    },

    created() {
        const milestonesCopy = JSON.parse(
            JSON.stringify(this.data.user_milestones)
        ); // to clone the array
        this.milestones = milestonesCopy.map((milestone) => {
            if (milestone.date_acquired) {
                milestone.date_acquired = dateUtility.toLocalDate(
                    milestone.date_acquired
                );
                return milestone;
            }
            return milestone;
        });
    },

    destroyed() {
        this.milestones = [];
    },
};
</script>
